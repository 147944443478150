<template>
  <div>
    <a-skeleton active :loading="this.loading" />

    <div class="container" v-if="!this.loading">
      <div class="title">
        {{this.chapter.title}}
        <a-divider />
      </div>
      <div class="toolbar">
        <a-button-group>
          <a-button type="primary" @click="handlePrevClick()">
            <a-icon type="left" />上一页
          </a-button>
          <a-button type="primary" @click="handleOnCustomSettingOpen">
            <a-icon type="setting" theme="filled" />设置
          </a-button>
          <a-button type="primary" @click="handleNextClick()">
            下一页
            <a-icon type="right" />
          </a-button>
        </a-button-group>
      </div>
      <div class="content-container content" v-html="chapter.content" />
      <div class="footer">
        <a-button-group>
          <a-button type="primary" @click="handlePrevClick()">
            <a-icon type="left" />上一页
          </a-button>
          <a-button type="primary" @click="handleNextClick()">
            下一页
            <a-icon type="right" />
          </a-button>
        </a-button-group>
      </div>
    </div>
    <a-back-top />
    <CustomSetting
      :visible="this.customSettingVisible"
      :fontSize="this.$store.state.customSettings.fontSize"
      :immersive="this.$store.state.customSettings.immersive"
      @callbackData="callbackData"
    />
    <div class="scroll"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import CustomSetting from '../components/CustomSetting'
import ContentCommon from './ContentCommon'

Vue.prototype.common = ContentCommon

const base_API_URL = 'api/novel/getChapter/'

export default {
  data() {
    return {
      ContentCommon,
      top: 66,
      path: 'content',
      novel: {
        id: this.$route.query.id,
        name: this.$route.query.name
      },
      chapter: {
        id: this.$route.query.id,
        title: 'Loading...',
        content: '',
        url: '',
        previousUrl: '',
        nextUrl: ''
      },
      url: this.$route.query.url,
      loading: true,
      busy: false,
      customSettingVisible: false
    }
  },
  components: {
    CustomSetting
  },
  computed: {
    storeNovel() {
      //获取store中的数据，需要将代码放置在computed中方可。
      return this.$store.state.novel
    },
    storeCustomSettings() {
      var setting = this.$store.state.customSettings
      return setting
    }
  },
  watch: {
    /*
      'customSettings.fontSize': {
         handler: function() {
            console.log('watch customSettings.fontSize: ', val.fontSize);
         },
      },
      */
    'storeCustomSettings.fontSize': {
      handler(val) {
        this.common.setFontSize(val)
      }
      //deep:true
    }
  },
  created() {
    var _this = this
    //console.log("created")

    //从localStorage中加载设置
    var data = JSON.parse(localStorage.getItem('CustomSettings'))
    if (data != null) {
      this.$store.commit('setCustomSettings', data)
    }

    document.onkeydown = function(e) {
      let keyCode = window.event.keyCode

      if (keyCode == 37) {
        //方向左
        _this.handlePrevClick()
      }
      if (keyCode == 39) {
        //方向右
        _this.handleNextClick()
      }
    }

    this.top = document.documentElement.clientHeight - 90

    //触底事件
    //window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    //console.log("beforeMount")
    this.common.setFontSize()
    this.fetchData(this.url)
  },
  mounted() {
    //console.log("mounted")
  },
  beforeUpdate() {
    //console.log("beforeUpdate")
    this.url = this.$route.query.url
    this.common.setHistory(this.url, this.novel)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollEvent)
  },

  methods: {
    callbackData(val) {
      this.customSettingVisible = val
    },
    routeRedirect() {
      setTimeout(
        () =>
          this.$router.push(
            {
              path: 'chapters',
              query: { id: this.novel.id, name: this.novel.name }
            },
            onComplete => {},
            onAbort => {}
          ),
        1000
      )
    },
    fetchData(url) {
      this.loading = true
      this.chapter.title = 'Loading...'
      this.chapter.content = ''

      this.$axios
        .get(base_API_URL, { params: { id:this.novel.id, url: url } })
        .then(res => {
          if (res.data.code != 0) {
            this.loading = true
            this.$message.error(res.data.message)

            this.routeRedirect()
            return
          }
          this.chapter = res.data.data
          this.loading = false

          document.title = this.chapter.title
        })
        .catch(error => {
          console.warn(error)
          this.loading = true
          this.$message.error('发生错误！即将返回列表页！')
          setTimeout(
            () =>
              this.$router.push(
                {
                  path: 'chapters',
                  query: { id: this.novel.id, name: this.novel.name }
                },
                onComplete => {},
                onAbort => {}
              ),
            1000
          )
        })
    },
    handleOnCustomSettingOpen() {
      this.customSettingVisible = true
    },
    handleScroll() {
      /*
        console.log('scrollTop:' + document.documentElement.scrollTop );
        console.log('clientHeight:' + document.documentElement.clientHeight );
        console.log('clientHeight:' + document.body.scrollHeight );
        console.log('');
        */
      //触底事件，自动跳转至下一页
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
        document.body.scrollHeight
      ) {
        //this.handleNextClick();
      }
    },
    handlePrevClick() {
      if (
        this.chapter == null ||
        this.chapter.previousUrl == '' ||
        this.chapter.previousUrl == 'NOTHING'
      ) {
        this.$message.warning('没有上一页了！')
        return
      }
      this.fetchData(this.chapter.previousUrl)
      this.$router.push(
        {
          path: this.path,
          query: {
            id: this.novel.id,
            name: this.novel.name,
            url: this.chapter.previousUrl
          }
        },
        onComplete => {},
        onAbort => {}
      )
    },
    handleNextClick() {
      if (
        this.chapter == null ||
        this.chapter.nextUrl == '' ||
        this.chapter.nextUrl == 'NOTHING'
      ) {
        this.$message.warning('没有下一页了！')
        return
      }
      this.fetchData(this.chapter.nextUrl)
      this.$router.push(
        {
          path: this.path,
          query: {
            id: this.novel.id,
            name: this.novel.name,
            url: this.chapter.nextUrl
          }
        },
        onComplete => {},
        onAbort => {}
      )
    }
  }
}
</script>
<style scoped>
.container {
  padding: 0px;
  margin: 0px;
  word-wrap: break-word;
}
.content-container {
  padding: 0px;
  margin: 0px;
}
body {
  --font-size: 20px;
}
.content {
  margin: 30px 0px 30px 0px;
  font-size: var(--font-size);
  text-indent:2em; 
  /*font-family: Arial,Helvetica,sans-serif,"宋体";*/
}
.row {
  line-height: 40px;
  height: 40px;
}
.title {
  padding: 10px 0px 0px 0px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.toolbar {
  text-align: center;
}
.footer {
  text-align: center;
}
</style>